<template>
  <div class="error-container">
    <div class="error-content">
      <el-row :gutter="20">
        <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
          <div class="pic-error">
            <el-image
              :src="require('@/assets/error_images/404.png')"
              class="pic-error-parent"
            />
            <el-image
              :src="require('@/assets/error_images/cloud.png')"
              class="pic-error-child left"
            />
          </div>
        </el-col>

        <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
          <div class="bullshit">
            <div class="bullshit-oops">{{ oops }}</div>
            <div class="bullshit-headline">{{ headline }}</div>
            <router-link class="bullshit-return-home" to="/">
              {{ jumpTime }}s&nbsp;{{ btn }}
            </router-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Page404",
    beforeRouteLeave(to, from, next) {
      clearInterval(this.timer);
      next();
    },
    data() {
      return {
        jumpTime: 5,
        oops: "抱歉!",
        headline: "当前页面不存在...",
        btn: "返回首页",
        timer: 0,
      };
    },
    mounted() {
      this.timeChange();
    },
    methods: {
      timeChange() {
        this.timer = setInterval(() => {
          if (this.jumpTime) {
            this.jumpTime--;
          } else {
            this.$router.push("/");
            clearInterval(this.timer);
          }
        }, 1000);
      },
    },
  };
</script>

<style lang="postcss">
  .error-container {
    position: relative;
    min-height: 100vh;
  }
  .error-content {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 40vw;
    height: 400px;
    transform: translate(-50%, -50%);
  }
  .pic-error-parent {
    width: 100%;
  }
  .pic-error {
    position: relative;
    float: left;
    width: 100%;
    overflow: hidden;
  }
  .pic-error-child.left {
    top: 17px;
    left: 220px;
    width: 80px;
    opacity: 0;
    animation-name: cloudLeft;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes cloudLeft {
    0% {
      top: 17px;
      left: 220px;
      opacity: 0;
    }

    20% {
      top: 33px;
      left: 188px;
      opacity: 1;
    }

    80% {
      top: 81px;
      left: 92px;
      opacity: 1;
    }

    100% {
      top: 97px;
      left: 60px;
      opacity: 0;
    }
  }
  .pic-error-child {
    position: absolute;
  }
  .bullshit-oops {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #1890ff;
    opacity: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  .bullshit-headline {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: #222;
    opacity: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
  }
  .bullshit-return-home {
    text-decoration: none;
    display: block;
    float: left;
    width: 110px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: #1890ff;
    border-radius: 100px;
    opacity: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(60px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .bullshit {
    position: relative;
    float: left;
    width: 300px;
    padding: 30px 0;
    overflow: hidden;
  }
</style>
